import React, { useEffect, useState } from 'react';
import styles from '../../../Secure.module.scss';
import { Methods, useFetch } from '../../../../../hooks/useFetch';
import { useNavigate, useParams } from 'react-router-dom';
import { ComponentLoader } from '../../../../../components/ComponentLoader';
import { CollapsableWallCard } from '../CollapsableWallCard';
import { Fixture } from '../../../../../types/DbModels';
import { FixtureDetails } from '../../../fixture/FixtureDetails';
import { Button } from '@mui/material';
import moment from 'moment';
import { group } from 'console';

export interface WallPageProps {
   tab: 'my' | 'followed' | 'all';
}

export const WallPage: React.FC<WallPageProps> = ({ tab }) => {
   const [groupedMatches, setGroupedMatches] = useState<Record<string, Array<Fixture>>>({});
   const [matches, setMatches] = useState<Array<Fixture>>([]);
   const [match, setMatch] = useState<Fixture | null>(null);
   const [bids, setBids] = useState<Array<Fixture>>([]);
   const [isLoading, setIsLoading] = useState(true);
   const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));

   const params = useParams();
   const navigate = useNavigate();

   const { makeRequest: fetchMatches } = useFetch(
      `/api/wall/${tab}`,
      {
         method: Methods.GET
      },
      true
   );

   const handleFetchMatches = async () => {
      const { data: response } = await fetchMatches({ params: { startDate: startDate } });

      const { matches, bids } = response.data;

      setGroupedMatches(groupedMatches);
      setMatches(matches);
      setBids(bids);
      setIsLoading(false);
   };

   useEffect(() => {
      const groupedMatches = matches.reduce((acc: Record<string, Array<Fixture>>, match: Fixture) => {
         const key = moment(match.date).format('YYYY-MM-DD');

         if (!acc[key]) {
            acc[key] = [];
         }

         acc[key].push(match);

         return acc;
      }, {});

      setGroupedMatches(groupedMatches);
   }, [matches]);

   const handleRefetchMatch = async (matchId: number | null = null) => {
      const { data: response } = await fetchMatches(matchId ? { params: { id: matchId, startDate: startDate } } : {});

      const matchIndex: number | null | undefined = matches.findIndex((match: Fixture) => match.id === matchId);

      const newlyFetchedMatch: Fixture = response.data.matches[0];

      if (matchIndex > -1) {
         newlyFetchedMatch.collapsed = matches[matchIndex].collapsed;

         matches.splice(matchIndex, 1);
      }

      const updatedMatches = [...matches, newlyFetchedMatch];

      updatedMatches.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

      setMatches(updatedMatches);

      const newBids = bids.filter((bid: any) => bid.match_id !== matchId);

      setBids([...newBids, ...response.data.bids]);

      setMatch(null);
   };

   useEffect(() => {
      if (params && params.id) {
         const currentFixture: Fixture | null | undefined = matches.find(
            (f: Fixture) => f.id === (params.id ? parseInt(params.id) : null)
         );

         if (currentFixture) {
            setMatch(currentFixture);
            return;
         }
      } else if (match) {
         handleRefetchMatch(match.id);
      }
   }, [params.id]);

   const handleSyncPrevious = () => {
      setStartDate(moment(startDate).subtract(1, 'day').format('YYYY-MM-DD'));
   };

   useEffect(() => {
      if (startDate) {
         handleFetchMatches();
      }
   }, [startDate]);

   const handleUpdateMatches = (matchToUpdate: Fixture) => {
      const updatedMatches = matches.map((match: Fixture) => {
         if (match.id === matchToUpdate.id) {
            return { ...match, collapsed: !match.collapsed };
         }

         return match;
      });

      setMatches(updatedMatches);
   };

   const handleRenderMatches = () => {
      if (isLoading) {
         return <ComponentLoader isLoading />;
      }

      const dates = Object.keys(groupedMatches);

      if (!dates.length) {
         return (
            <div
               onClick={() => navigate(`/wall/all`)}
               style={{
                  textAlign: 'center',
                  fontSize: '20px',
                  color: '#FFF',
                  fontWeight: 'bold'
               }}
            >
               You have no bids, see others bids!
            </div>
         );
      }

      return (
         <>
            {dates.map((date: string, index: number) => {
               const matches = groupedMatches[date];

               return (
                  <div key={index}>
                     <div
                        style={{
                           textAlign: 'center',
                           fontSize: '18px',
                           color: '#FFF',
                           letterSpacing: '2px',
                           padding: '10px',
                           backgroundColor: '#333',
                           boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                           margin: '20px 0',
                           fontWeight: 'bold'
                        }}
                     >
                        {date}
                     </div>
                     {matches.map((match: Fixture, index: number) => {
                        return (
                           <CollapsableWallCard
                              key={index}
                              fixture={match}
                              collapsable={false}
                              updateParent={handleUpdateMatches}
                           />
                        );
                     })}
                  </div>
               );
            })}
         </>
      );
   };

   if (match) {
      return <FixtureDetails fixture={match} />;
   }

   return (
      <div className={styles.favoritesWrapper}>
         <div style={{ display: 'grid', justifyContent: 'end', width: '98%' }}>
            <Button sx={{ justifySelf: 'end', alignSelf: 'center', color: '#FFF' }} onClick={handleSyncPrevious}>
               Sync previous
            </Button>
         </div>
         {handleRenderMatches()}
      </div>
   );
};
