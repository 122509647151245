import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Wall } from './wall/Wall';
import styles from './Secure.module.scss';
import { Footer } from './components/Footer';
import { Profile } from './profile/Profile';
import { SearchUsers } from './profile/SearchUsers';
// import { Header } from './components/Header';
import { HeaderProvider } from '../../context/HeaderContext';
import { Matches } from './matches/Matches';

const SecureNavigationWrapper: React.FC = () => {
   return (
      <HeaderProvider>
         <div className={styles.secureWrapper}>
            {/* <Header /> */}
            <Routes>
               <Route path="*" element={<Navigate to="/wall/my" replace />} />
               <Route path="/wall/:tab/:id?" element={<Wall />} />
               <Route path="/matches/:tab?/:id?/:league_id?/:fixture_id?" element={<Matches />} />
               <Route path="/users" element={<SearchUsers />} />
               <Route path="/users/:id" element={<Profile />} />
            </Routes>
            <Footer />
         </div>
      </HeaderProvider>
   );
};

export default SecureNavigationWrapper;
