import React, { useState } from 'react';
import { Collapse } from '@mui/material';
import styles from '../../Secure.module.scss';
import { Fixture, FixtureBid, PlayedBid } from '../../../../types/DbModels';
import { FixtureListingCard } from '../../browse/FixtureListingCard';

interface CollapsableCardProps {
   fixture: Fixture;
   updateParent: (league: any) => void;
   collapsable?: boolean;
}

export const CollapsableWallCard: React.FC<CollapsableCardProps> = ({ fixture, updateParent, collapsable = true }) => {
   const [open, setOpen] = useState<boolean>(Boolean(fixture.collapsed));

   const toggleCollapse = () => {
      setOpen(!open);
      updateParent({
         id: fixture.id,
         collapesed: fixture.collapsed
      });
   };

   const renderBids = () => {
      const bids = fixture.bids;

      if (!bids?.length) {
         return (
            <div
               className={styles.favoriteCollapseLeagueCardWidgetCountry}
               style={{
                  fontSize: '20px',
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  letterSpacing: '3px'
               }}
            >
               No bids found!
            </div>
         );
      }

      return bids.map((bid: FixtureBid, index: number) => {
         return (
            <div key={index} className={`${styles.fixturesFinishedWrapper} ${styles.fixturesFinishedWrapperWall}`}>
               <div className={styles.bidUsername}>{bid.username}</div>
               <div className={styles.fixturesFinishedWrapperBids}>
                  {bid.playedBids?.map((playerBid: PlayedBid, index: number) => {
                     const className = `${styles.fixturesFinishedWrapperBid}  ${
                        playerBid.result == null
                           ? styles.fixturesFinishedWrapperSelected
                           : playerBid.result === 1
                           ? styles.fixturesFinishedWrapperWon
                           : styles.fixturesFinishedWrapperLost
                     }`;

                     return playerBid.label && playerBid.quota ? (
                        <div className={className} key={index}>
                           <div className={styles.fixturesFinishedWrapperBidLabel}>{playerBid.label}</div>
                           <div className={styles.fixturesFinishedWrapperBidQuote}>{playerBid.quota}</div>
                        </div>
                     ) : null;
                  })}
               </div>
            </div>
         );
      });
   };

   return (
      <div className={styles.favoriteColleapseLeagueCard}>
         <div className={styles.matchCardHeader} style={{ zIndex: 1 }}>
            <FixtureListingCard
               fixture={fixture}
               collapsable={collapsable}
               expanded={true}
               toggleCollapse={toggleCollapse}
            />
         </div>
         <Collapse in={true} timeout="auto" sx={{ zIndex: 2 }} unmountOnExit>
            <div
               className={styles.fixturesList}
               style={{ background: 'transparent', display: 'flex', flexDirection: 'column', gap: '10px' }}
            >
               {renderBids()}
            </div>
         </Collapse>
      </div>
   );
};
